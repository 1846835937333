html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: (25 / 16) * 100%;
  line-height: 1;
}

body {
  height: 100%;
  width: 100%;
  font-size: (16 / 25) * 1rem;
  font-family: roboto, sans-serif;
  user-select: none;
  margin: 0px;
  overflow-x: hidden;
}

h1 {
  font-size: (14 / 25) * 1rem;
  line-height: (14 / 25) * 1rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  // white-space: nowrap;
  padding-bottom: 0.5rem;
  pointer-events: none;
  color: #003d7f;
  @media (min-width: 269px) {
    font-size: (25 / 25) * 1rem;
    line-height: 1rem;
    margin-left: (-3 / 25) * 1rem;
  }
}

p {
  margin: 0;
  margin-top: 0.5rem;
  line-height: 1rem;
  font-weight: 400;
  color: #f4881c;
}

.flex {
  display: flex;
}

.rightSideHeader {
  justify-content: space-between;
  align-items: center;
}

.labelBlue {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #214da3;
}

.liveWebinarTitle {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #3174f5;
  padding: 10px 15px;
}

.webinarPill {
  border: 1px solid #3174f5;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .widSubMsC {
    width: 100% !important;
  }
  .xpwhiteContainer {
    width: 100% !important;
    height: 50px !important;
    top: -30px !important;
    left: -15px !important;
  }
  .xpImg {
    width: 180px !important;
    left: -60px !important;
  }
  .whitImg {
    width: 150px !important;
    position: absolute !important;
    left: 180px;
  }
  .plus {
    right: 108px !important;
  }
  .drawShareCollabo {
    font-size: 22px !important;
    margin: 0px !important;
  }
  .smlOEC {
    top: -50px !important;
  }
  .feedbackNew {
    display: none !important;
  }
  .privacyNew {
    display: none !important;
  }
  .whiteboardFeedBack {
    display: none !important;
  }
  .contentText {
    top: -20px;
  }
}

@media only screen and (min-width: 600px) {
  .feedbackPrivacyTerm {
    display: none !important;
  }
}

.textDecorNone {
  text-decoration: none !important;
}

.labelsHeader {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #5d5770;
}

.flex-a-c {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.m-10 {
  margin: 10px;
}
