@supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */ 
    .newDrawer .longMenuDiv{
        height: 100% !important;
        position: relative !important;
    }
   .ChevronRight{
       /* position: absolute;
       top: 0;
       right: 0; */
   } 

   .closeImgContainer{
       position: absolute;
       top: 10px;
    }
  }