.cookie-consent-form {
    position: fixed;
    bottom: 0;
    z-index: 10000;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(247, 220, 220);
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content:space-around;
    align-items: center;
}

.cookie-consent-form-mini {
    position: fixed;
    bottom: 0;
    z-index: 10000;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(247, 220, 220);
    border-radius: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content:space-around;
    align-items: center;
}

.cookie-consent-form .cc-description {
    color: #3b3f3b;
    font-size: 0.6rem;
    /* text-align: center; */
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    justify-content: flex-start;
}

.cookie-consent-form-mini .cc-description-mini {
    color: #3b3f3b;
    font-size: 0.5rem;
    text-align: center;
}

.cookie-consent-form .submit-button-wrapper {
    text-align: center;
}

.cookie-consent-form .submit-button-wrapper input {
    border: none;
    border-radius: 4px;
    background-color: #3174F5;
    color: white;
    /* text-transform: uppercase; */
    padding: 10px 10px;
    font-weight: 200;
    font-size: 0.5rem;
    letter-spacing: 1px;
    margin-right: 0.5rem;
}

.cookie-consent-form-mini .submit-button-wrapper-mini input {
    border: none;
    border-radius: 4px;
    background-color: #3174F5;
    color: white;
    /* text-transform: uppercase; */
    padding: 10px 10px;
    font-weight: 400;
    font-size: 0.5rem;
    letter-spacing: 1px;
    margin-right: 0.5rem;
}

.cc-form-controls {
    width: 33%;
    display: flex;
    flex-direction: row;
    justify-content:right;
    align-items: center;
}

.cc-form-controls-mini {
    display: flex;
    flex-direction: row;
    justify-content:right;
    align-items: center;
}

.privacy-link {
    color: #717771;
    font-size: 0.5rem;
    text-align: center;
    text-decoration: none;
    margin-left: 0.5em;
}

.cookieImg {
    width: 33px;
    padding-left: 0.5rem;
}

.outline {
    /* background-color: #3174F588 !important; */
    background-color: transparent !important;
    border: solid 1px #3174F5 !important;
    color: #3174F5 !important;
    font-weight: 400 !important;
}