$color-1: #786CD9;
$color-2: #F5B453;
$color-3: #4ACED7;
$color-4: #D358AF;
$color-5: #606c38;
$color-6: #8C4843;
$btn-color: #4076ED;
$gray-color: #808080;
$small: 300px;
$medium: 900px;

.inclusion-main {
    text-align: center;
    font-family: lato;
    background: url("/inclusion/section_1_bg.svg");
    background-size: 100%;
    background-repeat: no-repeat;

    & h1 {
        text-transform: inherit;
        color: inherit;
    }

    & .btn-block {
        cursor: pointer;
    }

    & p {
        color: initial;
        text-align: justify;
        line-height: initial;
        font-weight: 500;
        margin: initial;
    }

    & header {
        position: relative !important;
        // padding-left: 10px;
    }

    & .heading {
        font-size: 22px;
        line-height: 1.3;
        padding: 20px 50px 0px 50px;
        font-weight: 700;
    }

    & .heading-1 {
        font-size: 55px;
        font-family: lato;
        font-weight: 700;
    }

    & .heading-2 {
        font-weight: 700;
        font-size: 22px;
        // padding: 20px;
        padding-bottom: 10px;
        line-height: 1.3;
    }

    & .heading-3 {
        font-size: 35px;
        line-height: 1.3;
        padding: 30px 50px 0px 200px;
        font-weight: 700;
    }

    & .content {
        font-size: 18px;
        line-height: 1.5;
    }

    & .section-1 {
        padding-top: 100px;
        padding-bottom: 30px;

        & .heading-1 {

            @media screen and (min-width: $small) and (max-width: $medium) {
                //css code
                font-size: 30px;
            }

            & .vector-1 {
                display: inline-block;
                background: url("/inclusion/vector_1.svg");
                background-size: 100% 100%;
                padding: 5px 30px 5px 60px;
            }
        }

        & .heading-2 {
            font-size: 35px;
        }

        & .content {
            font-size: 20px;
            // padding: 15px;
            padding: 15px 60px;
        }

        & .spacer {
            height: 175px;
        }
        & .spacer2 {
            height: 80px;
        }





        & .card-block {
            text-align: left;

            & .card-content {
                padding: 0px;

                & .banner {
                    height: 115px;
                    width: 100%;
                    background-size: 100%;

                    &.banner-1 {
                        background: url("/inclusion/section_1_card_banner_1.svg");
                    }

                    &.banner-2 {
                        background: url("/inclusion/section_1_card_banner_2.svg");
                    }

                    &.banner-3 {
                        background: url("/inclusion/section_1_card_banner_3.svg");
                    }

                    &.banner-4 {
                        background: url("/inclusion/section_1_card_banner_4.svg");
                    }
                }

                & .banner-icon {
                    // padding: 20px;
                    border-radius: 50%;
                    display: flex;
                    margin-top: -50px;
                    margin-left: 50px;
                    color: white;
                    height: 120px;
                    width: 120px;
                    align-items: center;
                    justify-content: center;

                    &.banner-icon-1 {
                        background: $color-1;
                    }

                    &.banner-icon-2 {
                        background: $color-2;
                    }

                    &.banner-icon-3 {
                        background: $color-3;
                    }

                    &.banner-icon-4 {
                        background: $color-4;
                    }
                    &.banner-icon-5 {
                        background: $color-5;
                    }
                    &.banner-icon-6 {
                        background: $color-6;
                    }
                }



                & .content {
                    padding: 20px 50px 0px 50px;
                    color: $gray-color;
                    min-height: 310px;
                    font-size: 18px;
                }

                & .action-btn {
                    padding: 20px 50px 20px 50px;
                    display: flex;
                    justify-content: flex-start;
                    cursor: pointer;
                    align-items: center;
                    font-size: 16px;

                    &.action-btn-1 {
                        color: $color-1;
                    }

                    &.action-btn-2 {
                        color: $color-2;
                    }

                    &.action-btn-3 {
                        color: $color-3;
                    }

                    &.action-btn-4 {
                        color: $color-4;
                    }
                    &.action-btn-5 {
                        color: $color-5;
                    }
                    &.action-btn-6 {
                        color: $color-6;
                    }
                }
            }
        }
    }

    & .section-2 {
        position: "relative";
        top: "25px";

        & .card-content {
            padding: 10px;

            & * {
                display: inline-block;
            }

            & .content {
                padding-right: 15px;
            }

            & .button-block {
                background: $btn-color;
                color: white;
                padding: 10px 25px;
                border-radius: 40px;
            }

        }
    }

    & .section-3 {
        background: #E4F9FF;
        text-align: left;
        padding-top: 40px;
        padding-bottom: 40px;

        & .list {
            padding-left: 30px;
            padding-right: 50px;

            & .heading-1 {
                font-size: 35px;
                padding-top: 40px;
            }

            & .list-block {
                display: inline-flex;
                padding: 30px 30px 0px 10px;

                & .icon-block {
                    padding-right: 25px;
                    color: #50CDF7;
                    padding-top: 5px;
                }

                & .content-block {

                    & .btn-block {
                        padding-top: 15px;
                        color: #50CDF7;
                        font-weight: 500;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    & .section-4 {
        background: #FDF5E9;
        text-align: left;
        padding-top: 40px;

        & .heading-1 {
            font-size: 40px;
            font-family: lato;
            font-weight: 700;
        }

        & .list {
            padding-left: 30px;


            & .list-block {
                display: inline-flex;
                padding: 30px 30px 0px 10px;

                & .icon-block {
                    padding-right: 25px;
                    color: #F5B453;
                    padding-top: 5px;
                }

                & .content-block {
                    // & .heading-2 {
                    //     font-weight: 700;
                    //     padding-bottom: 10px;
                    //     font-size: 22px;
                    //     line-height: 1.5;
                    // }

                    & .content {
                        font-size: 18px;
                        line-height: 1.5;
                    }

                    & .btn-block {
                        padding-top: 15px;
                        color: #F5B453;
                        font-weight: 500;
                        font-size: 16px;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    & .section-5 {
        background: #FAEDF7;

        & h1 {
            // font-size: 35px;
            // padding-bottom: 20px;
            font-size: 40px;
            padding-bottom: 20px;
            line-height: 1.2;
        }

        & .heading-2 {
            font-size: 35px;
            padding-bottom: 20px;
            line-height: 1.2;
        }

        & p {
            text-align: center;
            padding: 0px 10px 10px 10px;
            line-height: 1.5;
            font-size: 16px;
        }

        & .btn-block {
            display: flex;
            align-items: center;
            color: #D358AF;
            justify-content: center;
            font-size: 16px;
        }

    }

    & .section-6 {
        background: #E4F9FF;

        & h1 {
            font-size: revert;
            line-height: 1.2;
        }

        & .ms-edu-tools {
            padding-bottom: 15px;

            & .content-block {
                text-align: justify;
                line-height: 1.4;
                padding-left: 30px;
                padding-right: 30px;
                font-size: 16px;
            }

        }
    }

    & .section-7 {
        background: white;

        & .card-content {
            padding: 0;

            & .testimonial-feedback {
                padding: 25px;

                & p {
                    padding: 20px;
                    font-size: 18px;

                    &:nth-child(1) {
                        background: url("/skyBlueQuote.svg");
                        background-size: 15%;
                        background-repeat: no-repeat;
                    }
                }
            }

            & .testimonial-user {
                background: #E7FAFF;
                padding: 20px;
                line-height: 1.5;


                & h3,
                h5 {
                    margin: 0;
                }
            }
        }
    }

    & .section-8 {
        background: white;
        // padding: 20px;

    }
}

.video-dialog {
    width: 60vw;
    height: 30vw;
    overflow: hidden;
    @media screen and (min-width: $small) and (max-width: $medium) {
        width: 80vw;
        height: 40vw;
    }
}